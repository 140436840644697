<template>
  <v-container class="custom_container" >
    <div class="card_container" style="height: 100vh;background: rgb(12 12 12 / 44%);display: flex;justify-content: center;align-items: center;" >
      <p style="font-size: 4rem;color: white;padding: 2rem;" >
        Sustainable use of
        perennial plants for
        long-term particulate
        matter treatment.
      </p>
    </div>
  </v-container>

</template>
<script>
// @ is an alias to /src


import  {baseUrlMain } from "../const/api";

export default {
  name: 'Detail',
  data() {
    return {
      baseUrl: baseUrlMain,
      content: [],
      images: [],
      image_position: [],
      docs: [],
      video: [],
      lang: localStorage.getItem('lang'),
    }
  },
  methods: {}
}
</script>

