<template>
  <v-container class="custom_container" >
    <div class="card_container" style="margin-bottom: 2rem" >
      <div class="image-magnifier"
           @mousemove="handleMouseMove"
           @touchmove="handleTouchMove"
           @mouseleave="resetMagnifier"
           @touchend="resetMagnifier"
      >
        <div class="image-wrapper">
          <img src="../assets/image/map.png" :style="zoomStyle" ref="image" />
        </div>
      </div>
    </div>


    <carousel
        :responsive="{0:{items:3,nav:false,dots:false},600:{items:5,nav:false,dots:true}}"
    >
      <div @click="GotoPage('https://kmuttapp.commsk.dev')" >
        <img src="../assets/image/btn_link_2.png" >
      </div>
      <div  >
        <img src="../assets/image/btn_link_1.png" >
      </div>
      <div>
        <img src="../assets/image/btn_link_3.png" >
      </div>
      <div @click="GotoPage('/Sustainable')" >
        <img src="../assets/image/btn_link_.png" >
      </div>
      <div>
        <img src="../assets/image/btn_link_4.png" >
      </div>
      <div>
        <img src="../assets/image/btn_link_5.png" >
      </div>
      <div>
        <img src="../assets/image/btn_link_6.png" >
      </div>
      <div>
        <img src="../assets/image/btn_link_7.png" >
      </div>
    </carousel>
    s
  </v-container>
</template>
<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";

export default {
  name: 'Home',
  components:{carousel},
  data: () => ({
    read_more_1:false,
    read_more_2:false,
    isPanning: false,
    zoomed: false,
    zoomX: 0,
    zoomY: 0,
    mouseX: 0,
    mouseY: 0,
    images: [
      "https://placeimg.com/200/200/any?1",
      "https://placeimg.com/200/200/any?2",
      "https://placeimg.com/200/200/any?3",
      "https://placeimg.com/200/200/any?4",
      "https://placeimg.com/200/200/any?5",
      "https://placeimg.com/200/200/any?6",
      "https://placeimg.com/200/200/any?7",
      "https://placeimg.com/200/200/any?8",
      "https://placeimg.com/200/200/any?9",
      "https://placeimg.com/200/200/any?10"
    ]
  }),
  methods:{
    GotoPage(page){
      window.location = page;
    },
    handleMouseMove(event) {
      this.handleMove(event.clientX, event.clientY);
    },
    handleTouchMove(event) {
      const touch = event.touches[0];
      this.handleMove(touch.clientX, touch.clientY);
    },
    handleMove(clientX, clientY) {
      if (!this.zoomed) {
        this.zoomed = true;
      }
      const imageRect = this.$refs.image.getBoundingClientRect();
      const containerRect = this.$el.getBoundingClientRect();
      const imageWidth = imageRect.width;
      const containerWidth = containerRect.width;
      const imageRatio = imageWidth / containerWidth;
      const mouseX = clientX - containerRect.left;
      const mouseY = clientY - containerRect.top;

      const panSpeed = 0.1; // Adjust the pan speed as needed

      const zoomX = (containerWidth / 2 - mouseX) * imageRatio * panSpeed;
      const zoomY = (containerWidth / 2 - mouseY) * imageRatio * panSpeed;

      this.zoomX = zoomX;
      this.zoomY = zoomY;
      this.mouseX = mouseX;
      this.mouseY = mouseY;
    },
    resetMagnifier() {
      this.zoomed = false;
      this.zoomX = 0;
      this.zoomY = 0;
      this.mouseX = 0;
      this.mouseY = 0;
    }
  },
  computed: {
    zoomStyle() {
      if (this.zoomed) {
        return {
          transform: `scale(4) translate(${this.zoomX}px, ${this.zoomY}px)`
        };
      }
      return {};
    },
  },
}
</script>
<style>
.image-magnifier {
  position: relative;
  height:60vh;
  width: auto;
  overflow: hidden;
}

.image-magnifier .image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.image-magnifier img {
  height: 100%;
  transition: transform 0.3s ease;
}

</style>
