<template>
  <div>

    <nav >
    <div class="menu" >
      <button @click="drop_down_menu == true ? drop_down_menu = false : drop_down_menu = true " >
        <img class="icon-rotate menu-icon" :class="{ 'icon-rotate-to': drop_down_menu }" src="./assets/image/menu.png" ></button>
     <img class="logo-menu" src="./assets/image/logo.png" >
      <button @click="Setlang()" >
        <img  class="lang-menu" src="./assets/image/search.png" >
      </button>
    </div>
    </nav>
    <div style="padding-top: 8rem" >
      <router-view/>
    </div>

  </div>
</template>

<script>


export default {
  name: 'App',

  components: {},
  data() {
    return {
      drop_down_tgbi: false,
      drop_down_floor: false,
      drop_down_menu: false,
      is_loading: true,
      lang: localStorage.getItem("lang"),
    }
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    GotoPage(page){
      this.$router.push({ path: page})
    },
    Setlang(){
      localStorage.setItem("lang",this.lang == 'en' ? 'th':'en')
      this.lang = localStorage.getItem("lang")
    },
  },
  mounted() {
    setTimeout(()=>{
      this.is_loading = false;
    },7000);
  },
  beforeUnmount() {

  },
  created() {
    //console.log(process.env)
  }
};

</script>
<style>
.footer_custom {
  justify-content: center;
}

.v-toolbar__content {
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}

.version_show {
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.v-application .blue.lighten-5 {
  width: 300px !important;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}

.v-application ul, .v-application ol {
  padding-left: 55px !important;
}

.version_check {
  font-size: 0.9rem;
  padding: 1rem;
  color: #b3a6a6;
}
</style>
