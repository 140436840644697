import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Build from '../views/Build'
import Land from '../views/Land'
import Water from '../views/Water'
import Energy from '../views/Energy'
import Material from '../views/Material'
import Environment from '../views/Environment'
import Protect from '../views/Protect'
import Detail from '../views/Detail'
import Innovation from '../views/Innovation'
import Sustainable from '../views/Sustainable'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Sustainable',
    name: 'Sustainable',
    component: Sustainable
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/build',
    name: 'Build',
    component: Build
  },
  {
    path: '/land',
    name: 'Land',
    component: Land
  },
  {
    path: '/land',
    name: 'Land',
    component: Land
  },
  {
    path: '/water',
    name: 'Water',
    component: Water
  },
  {
    path: '/energy',
    name: 'Energy',
    component: Energy
  },
  {
    path: '/material',
    name: 'Material',
    component: Material
  },
  {
    path: '/environment',
    name: 'Environment',
    component: Environment
  },
  {
    path: '/protect',
    name: 'Protect',
    component: Protect
  },
  {
    path: '/innovetion',
    name: 'Innovation',
    component: Innovation
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
