import {API, instance} from "../const/api";




export async function getTGBICategory(category,Calback){

    await instance.get(API.TGBICATEGORY+"/"+category)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function getTGBIDetail(id,Calback){

    await instance.get(API.TGBIDETAIL+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}
